<template>
	<!-- This example requires Tailwind CSS v2.0+ -->
	<div class="relative bg-white overflow-hidden">
		<div class="mx-auto grid grid-cols-1 md:grid-cols-1">
		<div class="">
			<a href="/Register"><img class="" src="images/home_hero_sasl_new.jpg" alt="Click here to access our Sign Language course" /></a>
		</div>	
				<div class="pt-4 px-4 sm:px-6 lg:px-4"></div>
					<div class="sm:text-center lg:text-left lg:ml-6 w-full">
					<div>
						<h1 class="text-2xl sm:text-3xl tracking-tight font-extrabold text-gray-900 xl:text-3xl gap-2">
							<div class="block">Looking to learn South African Sign Language? </div>
							<div class="block text-primary-900">You’ve come to the right place!</div>
						</h1>
						<p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-lg lg:mx-0 tracking-tighter">
							<b>Follow these 3 easy steps to start your journey to learning SASL:</b> <br>1. Register for an account. 
							<br>2. Check your email and sign in using your login details. <br>3. Visit the store to purchase the course.  Activate it, and start learning SASL
						</p>
						<div class="mt-5 block lg:flex tracking-tight">
							<div class="sm:ml-3">
								<div @click="linkClick('hero-register', '/register')" 									
									class="buttonprimary py-3 cursor-pointer">
									Sign Up Now
								</div>								
							</div>	
							<div class="sm:ml-3">
							<div @click="linkClick('activation-guide', '/activationguide')" 									
									class="buttonprimary py-3 cursor-pointer">
									Course Activation Guide
								</div>													
							</div>
						</div>
					</div>
					<!-- <div>
						<h1 class="text-xl tracking-tight font-extrabold text-gray-900 gap-2 mt-6">
							<div class="block xl:inline">Supporting inclusivity and accessibility for the Deaf</div>							
						</h1>
						<p class="mt-2 text-base text-gray-500 sm:text-lg sm:max-w-xl  md:text-lg lg:mx-0 tracking-tighter">
							DEAFinition are a non-profit company providing a range of services and funding opportunities to promote equal access for the Deaf community.
						</p>
						<div class="w-full grid grid-cols-1 space-x-1">
							<div class="grid grid-cols-2 items-center">
								<div><img class="h-20 w-full object-contain mx-auto" src="images/app_install.png" alt="" /></div>
								<div class="text-primary-900 text-xl font-semibold mx-auto mt-2">App now available!</div>
							</div>
							<div class="grid grid-cols-2">
								<div class="mx-auto">
									<a href="https://apps.apple.com/us/app/sasl-deafinition/id1583012841?ign-mpt=uo%3D4"><img class="h-10" src="images/appstore_apple.png" alt="" /></a>
								</div>
								<div class="mx-auto">
									<a href="https://play.google.com/store/apps/details?id=com.edeafmobile&gl=ZA"><img class="h-10" src="images/appstore_google.png" alt="" /></a>
								</div>
								<div class="mx-auto">
									<a href="https://apps.apple.com/us/app/sasl-deafinition/id1583012841?ign-mpt=uo%3D4"><img class="h-20 mt-3" src="images/qr_apple.png" alt="" /></a>
								</div>
								<div class="mx-auto">
									<a href="https://play.google.com/store/apps/details?id=com.edeafmobile&gl=ZA"><img class="h-20 mt-3" src="images/qr_android.png" alt="" /></a>
								</div>
							</div>
						</div>
					</div> -->
				</div>
			</div>
		</div>	
</template>

<script>
export default {
	methods: {
		linkClick(name, route) {
			this.$gtag.event('footer-' + name, { method: 'Google' });
			this.$router.push(route);
		},
	}
};
</script>

<style></style>
